
const WhyAppsysContent = [
  {
    icon: "fa-regular fa-users",
    title: "Expert Trainers",
    desc: "Learn from industry experts. All our trainers are working professionals with extensive years of experience in the industry & excellent teaching skills.",
    dataAos: "fade-up",
  },
  {
    icon: "fa-regular fa-indian-rupee-sign",
    title: "Lowest Fees",
    desc: "We understand the cost of education puts it out of reach for many. Our Programs come at fees that are affordable for you.",
    dataAos: "zoom-in",
  },
  {
    icon: "fa-regular fa-clock",
    title: "Flexible Batch Times",
    desc: "Our training programs are arranged in a more flexible manner, you can choose your own training schedule. Weekend batches are also available for working professionals.",
    dataAos: "fade-down",
  },
  {
    icon: "fa-regular fa-book",
    title: "Course Curriculum",
    desc: "Our course curriculum has been meticulously designed from basics to advanced topics to ensure that even the fresher can master the concepts easily. We tailor the course content periodically according to the changing needs of the industry.",
    dataAos: "fade-up",
  },
  {
    icon: "fa-regular fa-flask",
    title: "Lab-Access",
    desc: "For most of the courses participants can access our labs. These labs are available every day without any downtime. This will give them more flexibility on their learning schedule.",
    dataAos: "zoom-in",
  },
  {
    icon: "fa-regular fa-chart-column",
    title: "Work on live projects",
    desc: " We will provide an opportunity to work on live projects related to various industries. This helps the participants to gain the holistic real time insight of the projects. Our subject matter experts will help to complete the project successfully.",
    dataAos: "fade-down",
  },
];

export default WhyAppsysContent;
