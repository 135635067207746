const contactContent = [
  {
    title: "Address",
    iconClass: "fa fa-map-marker",
    details:
      "P04, 4th Floor, Gold Signature, 95,Jammu & Kashmir Bank, Frazer Town, Bangalore, Karnataka 560005",
  },
  {
    title: "Email Address",
    iconClass: "fa fa-envelope",
    details: " enquiry@appsysit.com",
  },
  {
    title: "Phone Number",
    iconClass: "fa fa-phone",
    details: " +91 9071 271 271",
  },
];

export default contactContent;
