/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect } from "react";
import SiteBreadcrumb from "../../Components/Breadcumb/breadcumb";
import ContactInfo from "./ContactInfo";
import SectionTitle from "../../Components/Section/SectionTitle";
import ContactForm from "./ContactForm";
import contactContent from "../../Components/contents/contactContent";
import { useAllPrismicDocumentsByType } from "@prismicio/react";
import Helmet from "react-helmet";

const Contact = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const [document] = useAllPrismicDocumentsByType("contact_type");
  const contactPageContent = document && document[0]?.data;
  console.log(contactPageContent);

  const ContectPrismic = contactPageContent?.body[0]?.items.map(
    (item: any, index: number) => {
      return (
        <div className="col-md-4" key={index}>
          <ContactInfo
            boxClass="sm-mb-30 h-100"
            title={item.title[0].text}
            iconClass={item.contact_icon[0].text}
            address={item.contact_details[0].text}
          />
        </div>
      );
    }
  );

  const Contactcontent = contactContent.map((data, i) => {
    return (
      <div className="col-md-4" key={i}>
        <ContactInfo
          boxClass="sm-mb-30 h-100"
          title={data.title}
          iconClass={data.iconClass}
          address={data.details}
        />
      </div>
    );
  });
  return (
    <React.Fragment>
      <Helmet>
        <title>Contact us - www.appsysit.com</title>
       
      </Helmet>
      <SiteBreadcrumb
        pageTitle={
          contactPageContent
            ? contactPageContent?.body[0]?.primary.title[0].text
            : "Contact Us"
        }
        pageName={
          contactPageContent
            ? contactPageContent?.body[0]?.primary.subtitle[0].text
            : "Contact"
        }
        breadcrumbsImg={
          contactPageContent
            ? contactPageContent?.body[0]?.primary.bgimg.url
            : "./bg/bgcontact.jpeg"
        }
      />

      <div className="rs-contact style1 event-bg pt-50 md-pt-60 pb-100 md-pb-80">
        <div className="container pb-66 md-pb-46">
          <div className="row gutter-35">
            {contactPageContent ? ContectPrismic : Contactcontent}
          </div>
        </div>

        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-6 md-mb-30">
              <div className="map-canvas">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.610664535183!2d77.61198001477085!3d12.996735590839412!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae16f689f99a09%3A0x3ba1c552abd5094b!2sV6%20Appsys%20Infotech%20Pvt.%20Ltd!5e0!3m2!1sen!2sin!4v1672230268097!5m2!1sen!2sin"
                  width="600"
                  height="450"
                ></iframe>
              </div>
            </div>
            <div className="col-lg-6 pl-60 md-pl-14">
              <div className="contact-widget">
                <SectionTitle
                  sectionClass="sec-title3 mb-36"
                  descClass="new-desc"
                  description="Please fill this form, we'll get back to you as soon as possible!"
                />
                <ContactForm
                  submitBtnClass="btn-send"
                  btnText="Submit"
                  fieldRequired={
                    <select className="form-select">
                      <option>Choose your reqirements</option>
                      <option value="1">Courses</option>
                      <option value="2">Placement</option>
                      <option value="3">one month free Training</option>
                    </select>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Contact;
