
const advancejavaContent = [
  {
    cardimg: "./img/corejava.jpeg",
    title: "Core Java",
    cardLink: "/course_core_java",
  },
];

export default advancejavaContent;
