import React, { useEffect } from "react";
import SectionTitle from "../../Components/Section/SectionTitle";
import CarrierSingle from "../../Components/Section/CarrierSingle";
import AOS from "aos";
import CarrierContent from "../../Components/contents/carrierContent";

const Carrier = (props: any) => {
  useEffect(() => {
    AOS.init();
  });
  const { carrierSlice } = props;

  const bgStyle = {
    backgroundImage: `url(${
      carrierSlice ? carrierSlice?.body[2]?.primary?.bg_img : "./img/bg3.png"
    })`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
  };

  const prismicCarrier = carrierSlice?.body[2]?.items.map(
    (item: any, index: number) => {
      return (
        <div className="col-lg-4 col-md-6 mb-30 pr-md-20" key={index}>
          <div
            data-aos={item.data_aos[0].text}
            data-aos-duration="1200"
            data-aos-delay="150"
          >
            <CarrierSingle
              courseClass="course-wrap"
              courseTitle={item.carrier_title[0].text}
              subTitle1={item.carrier_subtitle[0].text}
              subTitle2={item.carrier_subtitle2[0].text}
              courseList1={item.course_details[0].text}
              courseList2={item.course_duration[0].text}
              courseList3={item.course_list1[0].text}
              courseList4={item.course_list2[0].text}
              courseList5={item.course_list3[0].text}
              Button="Apply Here"
              applyLink="/contact"
            />
          </div>
        </div>
      );
    }
  );

  const carrierPage = CarrierContent.map((data, i) => {
    return (
      <div className="col-lg-4 col-md-6 mb-30 " key={i}>
        <div
          data-aos={data.dataAos}
          data-aos-duration="1200"
          data-aos-delay="150"
        >
          <CarrierSingle
            courseClass={data.courseClass}
            courseTitle={data.courseTitle}
            subTitle1={data.subTitle1}
            subTitle2={data.subTitle2}
            courseList1={data.courseList1}
            courseList2={data.courseList2}
            courseList3={data.courseList3}
            courseList4={data.courseList4}
            courseList5={data.courseList5}
            Button={data.Button}
            applyLink={data.applyLink}
          />
        </div>
      </div>
    );
  });
  return (
    <div
      className="rs-popular-courses style2 pt-50 pb-50 md-pt-64 md-pb-90"
      style={bgStyle}
    >
      <div className="container">
        <div data-aos="zoom-in" data-aos-duration="1200" data-aos-delay="150">
          <SectionTitle
            sectionClass="sec-title mb-60 text-center md-mb-30"
            subtitleClass="sub-title primary"
            subtitle={
              carrierSlice
                ? carrierSlice?.body[2]?.primary?.sub_title[0].text
                : "CHOOSE YOUR CAREER"
            }
            titleClass="title mb-0"
            title={
              carrierSlice
                ? carrierSlice?.body[2]?.primary?.title[0].text
                : "BUILD YOUR SKILLS"
            }
          />
        </div>

        <div className="row ml-lg-90 mr-lg-90">{carrierSlice ? prismicCarrier : carrierPage}</div>
      </div>
    </div>
  );
};

export default Carrier;
