/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Slider from "react-slick";
import SectionTitle from "../../Components/Section/SectionTitle";
import BrandContent from "../../Components/contents/BrandContent";

const Brand = (props: any) => {
  const { brandSlice } = props;

  const sliderSettings = {
    dots: false,
    centerMode: false,
    infinite: true,
    arrows: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const prismicBrand = brandSlice?.body[6]?.items.map(
    (item: any, index: number) => {
      return (
        <div className="partner-item" key={index}>
          <div className="logo-img">
            <a href="#">
              <img className="main-logo" src={item.client_img.url} alt="" />
            </a>
          </div>
        </div>
      );
    }
  );

  const brandContent = BrandContent.map((data, i) => {
    return (
      <div className="partner-item" key={i}>
        <div className="logo-img">
          <a href="#">
            <img className="main-logo" src={data.imgLink} alt="" />
          </a>
        </div>
      </div>
    );
  });

  return (
    <div className={"rs-partner pt-30 pb-50 md-pt-70 md-pb-70 gray-bg"}>
      <div className="container">
        <SectionTitle
          sectionClass="sec-title mb-60 text-center md-mb-30"
          titleClass="title mb-0"
          title={
            brandSlice
              ? brandSlice?.body[6].primary.title[0].text
              : "SOME OF OUR CLIENTS"
          }
        />
        <Slider {...sliderSettings}>
          {brandSlice ? prismicBrand : brandContent}
        </Slider>
      </div>
    </div>
  );
};

export default Brand;
