const wordpressContent = [
  {
    cardimg: "./img/drupal.jpeg",
    title: "Drupal",
    cardLink: "/course_drupal",
  },
  {
    cardimg: "./img/magento.jpeg",
    title: "Magento",
    cardLink: "/course_magento",
  },
];

export default wordpressContent;
