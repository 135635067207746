const javascript = [
  {
    cardimg: "./img/html.jpeg",
    title: "Html & css",
    cardLink: "/course_html_css",
  },
  {
    cardimg: "./img/bootstrap.jpeg",
    title: "Bootstrap",
    cardLink: "/course_bootstrap",
  },

  {
    cardimg: "./img/angular.png",
    title: "Angular",
    cardLink: "/course_ajax_angular",
  },
];

export default javascript;
