import About from "./About";
import Brands from "./Brands";
import Testimonial from "./Testimonial";
import Carrier from "./Carrier";
import Courses from "./Courses";
import Whyappsys from "./Whyappsys";
import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { useEffect } from "react";
import Banner from "./banner";
import { Helmet } from "react-helmet";

const Home = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const [document] = useAllPrismicDocumentsByType("homepage_type");
  const homePageContent = document && document[0]?.data;

  return (
    <div>
      <Helmet>
        <title>
          Liferay Training | Java/J2EE Training | PHP | Job Guaranteed Program -
          www.appsysit.com
        </title>
      </Helmet>

      <Banner bannerSlice={homePageContent} />

      <About aboutBanner={homePageContent} />

      <Carrier carrierSlice={homePageContent} />

      <Courses courseSlice={homePageContent} />

      <Whyappsys whyapsysSlice={homePageContent} />

      <Testimonial testimonialSlice={homePageContent} />

      <Brands brandSlice={homePageContent} />
    </div>
  );
};

export default Home;
