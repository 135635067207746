
const courseContent = [
  {
    catLink: "/course_java",
    courseClass: "subject-wrap bgc1",
    title: "JAVA",
    cardImg: "./img/java.jpeg",
    dataAos: "fade-left",
  },

  {
    catLink: "/course_liferay",
    courseClass: "subject-wrap bgc1",
    title: "LIFERAY",
    cardImg: "./img/liferay.jpeg",
    dataAos: "fade-up",
  },

  {
    catLink: "/course_php",
    courseClass: "subject-wrap bgc1",
    title: "PHP",
    cardImg: "./img/php.jpeg",
    dataAos: "fade-right",
  },

  {
    catLink: "/course_uiux",
    courseClass: "subject-wrap bgc1",
    title: "UX & UI",
    cardImg: "./img/uiux.jpeg",
    dataAos: "fade-left",
  },
  {
    catLink: "/course_mobileapp",
    courseClass: "subject-wrap bgc1",
    title: "MOBILE APP",
    cardImg: "./img/mobileapp.jpeg",
    dataAos: "fade-up",
  },

  {
    catLink: "/course_softwaretesting",
    courseClass: "subject-wrap bgc1",
    title: "SOFTWARE TESTING",
    cardImg: "./img/testing.jpeg",
    dataAos: "fade-right",
  },
];

export default courseContent;
