const PhpContent = [
  {
    cardimg: "./img/wordpress.jpeg",
    title: "Wordpress",
    cardLink: "/course_wordpress",
  },
  {
    cardimg: "./img/drupal.jpeg",
    title: "Drupal",
    cardLink: "/course_drupal",
  },
  {
    cardimg: "./img/magento.jpeg",
    title: "Magento",
    cardLink: "/course_magento",
  },
];

export default PhpContent;
