
const androidContent = [
  {
    cardimg: "./img/phonegap.jpeg",
    title: "PhoneGap",
    cardLink: "/course_Phonegap",
  },
];

export default androidContent;
