import React from "react";
import "../Assets/css/App.css";
import { Route, Routes } from "react-router-dom";
import CategoriesSingleTwo from "../Components/Section/catagories";
import Contact from "../Pages/Contact/Contact";
import Home from "../Pages/Home/Home";
import Placement from "../Pages/Placement/Placement";
import Java from "../Pages/Courses/coursemainPage/course_java";
import Liferay from "../Pages/Courses/coursemainPage/course_liferay";
import Php from "../Pages/Courses/coursemainPage/course_php";
import Testing from "../Pages/Courses/coursemainPage/course_testing";
import Mobileapp from "../Pages/Courses/coursemainPage/course_mobileapp";
import Uiux from "../Pages/Courses/coursemainPage/course_uiux";
import Corejava from "../Pages/Courses/innercourses/Corejava";
import AdvanceJava from "../Pages/Courses/innercourses/AdvanceJava";
import LiferayPortal from "../Pages/Courses/innercourses/Liferayportal";
import LiferayDeveloper from "../Pages/Courses/innercourses/LiferayDeveloper";
import LiferayUi from "../Pages/Courses/innercourses/LiferayUi";
import Wordpress from "../Pages/Courses/innercourses/Wordpress";
import Drupal from "../Pages/Courses/innercourses/Drupal";
import Magento from "../Pages/Courses/innercourses/magento";
import Htmlcss from "../Pages/Courses/innercourses/Htmlcss";
import Bootstrap from "../Pages/Courses/innercourses/Bootstrap";
import Javascript from "../Pages/Courses/innercourses/Javascript";
import Angular from "../Pages/Courses/innercourses/Angular";
import Phonegap from "../Pages/Courses/innercourses/Phonegap";
import Android from "../Pages/Courses/innercourses/Android";
import Selenium from "../Pages/Courses/innercourses/Selenium";
import ManualTest from "../Pages/Courses/innercourses/Manualtest";
import Testimonials from "../Pages/Testimonials/Testimonials";

const App: React.FC = () => {



  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/categories" element={<CategoriesSingleTwo />} />
      <Route path="/testimonial" element={<Testimonials />} />
      <Route path="/placement" element={<Placement />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/course_java" element={<Java />} />
      <Route path="/course_liferay" element={<Liferay />} />
      <Route path="/course_php" element={<Php />} />
      <Route path="/course_softwaretesting" element={<Testing />} />
      <Route path="/course_mobileapp" element={<Mobileapp />} />
      <Route path="/course_uiux" element={<Uiux />} />
      <Route path="/course_core_java" element={<Corejava />} />
      <Route path="/course_advance_java" element={<AdvanceJava />} />
      <Route path="/course_liferay_portal" element={<LiferayPortal />} />
      <Route path="/course_liferay_developer" element={<LiferayDeveloper />} />
      <Route path="/course_liferay_ui_developement" element={<LiferayUi />} />
      <Route path="/course_wordpress" element={<Wordpress />} />
      <Route path="/course_drupal" element={<Drupal />} />
      <Route path="/course_magento" element={<Magento />} />
      <Route path="/course_html_css" element={<Htmlcss />} />
      <Route path="/course_bootstrap" element={<Bootstrap />} />
      <Route path="/course_javascript" element={<Javascript />} />
      <Route path="/course_ajax_angular" element={<Angular />} />
      <Route path="/course_phonegap" element={<Phonegap />} />
      <Route path="/course_android" element={<Android />} />
      <Route path="/course_selenium" element={<Selenium />} />
      <Route path="/course_manual_testing" element={<ManualTest />} />
    </Routes>
  );
};

export default App;
