import Aos from "aos";
import { useEffect, useState } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";
import "../../Assets/css/banner.css";
import bannerContent from "../../Components/Section/bannerContent";
import { useLocation } from "react-router-dom";

const Banner = (props: any) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    Aos.init();
  });

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === bannerContent.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? bannerContent.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex: React.SetStateAction<number>) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  const { bannerSlice } = props;

  const prismicSlice = bannerSlice?.body[0].items.map(
    (item: any, i: number) => {
      return (
        <CarouselItem
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}
          key={i}
        >
          <div className="active">
            <img
              style={{ opacity: "0.85" }}
              src={item.bannerimg.url}
              alt=""
              className="gg "
              data-aos="fade-right"
              data-aos-duration="4000"
            />
            <div className="carousel-caption">
              <h1>{item.title[0].text}</h1>
              <a href="/contact">
                <button className="banner_btn">Register Now</button>
              </a>
            </div>
          </div>
        </CarouselItem>
      );
    }
  );

  const slides = bannerContent.map((item, i) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={i}
      >
        <div className="active" data-aos="">
          <img src={item.bgimg} alt="" className="gg" />
          <CarouselCaption captionText="" captionHeader={item.title}>
            <a href="/contact">
              <button className="banner_btn">Register Now</button>
            </a>
          </CarouselCaption>
        </div>
      </CarouselItem>
    );
  });
  return (
    <div className="marq">
      <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        keyboard={false}
        pause={false}
        ride="carousel"
        interval="5000"
        className="carousel-fade"
        {...bannerContent}
      >
        <CarouselIndicators
          items={bannerContent}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {bannerSlice ? prismicSlice : slides}
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={previous}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={next}
        />
      </Carousel>
    </div>
  );
};
export default Banner;
