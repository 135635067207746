/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect } from "react";
import AOS from "aos";
import SectionTitle from "../../Components/Section/SectionTitle";

const About = (props: any) => {
  useEffect(() => {
    AOS.init();
  });
  const { aboutBanner } = props;

  return (
    <div
      id="about"
      className="rs-about style1 pt-90 pb-30 md-pt-70 md-pb-20"
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <SectionTitle
              sectionClass="sec-title "
              titleClass="title px-2"
              title={
                aboutBanner
                  ? aboutBanner?.body[1]?.primary?.title[0].text
                  : "About Us"
              }
              descClass="desc lg-pr-36 md-h-100 px-3 text-justify"
              description={
                aboutBanner
                  ? aboutBanner?.body[1]?.primary?.about_description[0].text
                  : "AppSys is a leading training institution in Bangalore. We are specialized in giving excellent training courses in various technologies especially in Liferay and Java. We have industry leaders in Liferay and Java who are working in top MNC Companies. The course structure is designed after an intensive research and vast experience in various specialized skills to get the developers market ready. We also provide 100% real time scenarios in all the courses. With our real time trainer’s expertise and tailor-made training curriculum you can meet your career objectives whether you are a student, fresher or a working professional. We admit that your career must be built to your own specifications, that’s why our training target on 1-to-1 solutions, based on training courses from top Developers and Trainers."
              }
              animateName="fade-left"
              animateDuration="1200"
            />
          </div>
          <div className="col-lg-6 pl-20 order-last md-pl-14 md-mb-30">
            <div
              data-aos="zoom-in"
              data-aos-duration="1200"
              data-aos-delay="150"
            >
              <div className="img-part">
                <img src={"./img/about.jpeg"} alt="Main Image" />
              </div>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-6">
            <SectionTitle
              sectionClass="sec-title "
              titleClass="title px-2"
              title={
                aboutBanner
                  ? aboutBanner?.body[1]?.primary?.our_mission[0].text
                  : "Our Mission"
              }
              descClass="desc lg-pr-36 px-2"
              description={
                aboutBanner
                  ? aboutBanner?.body[1]?.primary?.our_description2[0].text
                  : "Our mission is to deliver skills and knowledge that significantly increase our course participants' on-the-job productivity, thereby enhancing their contributions to the goals of their organization."
              }
              animateName="fade-left"
              animateDuration="1200"
            />
          </div>
          <div className="col-lg-6">
            <SectionTitle
              sectionClass="sec-title "
              titleClass="title px-2"
              title={
                aboutBanner
                  ? aboutBanner?.body[1]?.primary?.our_vision[0].text
                  : "Our Vision"
              }
              descClass="desc lg-pr-36 px-2"
              description={
                aboutBanner
                  ? aboutBanner?.body[1]?.primary?.our_description1[0].text
                  : "Our Vision is to help individuals to identify and achieve their development, growth and career goals."
              }
              animateName="fade-left"
              animateDuration="1200"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
