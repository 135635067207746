import React, { useEffect } from "react";
import Pagebreadcumb from "../../../Components/Breadcumb/pagebreadcumb";
import SectionTitle from "../../../Components/Section/SectionTitle";
import { usePrismicDocumentByUID } from "@prismicio/react";
import advancejavaContent from "../courseContents/advancejavaContent";
import Widget from "../../../Components/Section/widget";
import DegreeSingle from "../../../Components/card/courseCard";
import { Helmet } from "react-helmet";

const AdvanceJava = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const [document] = usePrismicDocumentByUID(
    "innercourse_type",
    "advancejavaid"
  );
  const AdvanceJava = document && document?.data;
  console.log(AdvanceJava);

  const advancejavaPrismic = AdvanceJava?.body[0]?.items.map(
    (item: any, index: number) => {
      return (
        <div className="col-lg-3 col-md-6 " key={index}>
          <DegreeSingle
            cardimg={item.cardimg.url}
            title={item.card_title[0].text}
            cardLink={item.link[0].text}
          />
        </div>
      );
    }
  );

  const advancejavalocal = advancejavaContent.map((data, i) => {
    return (
      <div className="col-lg-3 col-md-6" key={i}>
        <DegreeSingle
          cardimg={data.cardimg}
          title={data.title}
          cardLink={data.cardLink}
        />
      </div>
    );
  });
  return (
    <React.Fragment>
      <Helmet>
        <title>Advance Java - www.appsysit.com</title>
      </Helmet>
      <div className="rs-degree style1 modify pb-70 md-pb-40">
        <Pagebreadcumb
          title={
            AdvanceJava
              ? AdvanceJava?.body[0].primary.course_title[0].text
              : "Advanve Java (J2EE)"
          }
          description={
            AdvanceJava
              ? AdvanceJava?.body[0].primary.course_desc[0].text
              : "Advanced Java is the next advanced level concept of Java programming. This high level java programming basically uses two Tier Architecture, i.e. Client and Server. It comprises the very complex advanced programming."
          }
          bgimg={
            AdvanceJava
              ? AdvanceJava?.body[0].primary.topimg.url
              : "./bg/bgadvancejava.png"
          }
        />
        <div className="container">
          <div className="row mb-20">
            <div className="col pt-50">
              <SectionTitle
                sectionClass="sec-title "
                titleClass="title"
                title={
                  AdvanceJava
                    ? AdvanceJava?.body[0].primary.course_overview[0].text
                    : "Overview"
                }
                descClass="desc"
                description={
                  AdvanceJava
                    ? AdvanceJava?.body[0].primary.overview_desc[0].text
                    : "Advanced Java is a platform-independent, Java-centric environment from Sun for developing, building and deploying Web-based enterprise applications online. It uses two-tier architecture i.e. client-side architecture and server-side or backend architecture. It is used for dynamic Web site creation, networking & database handling."
                }
              />
            </div>
            <div className="row">
              <SectionTitle
                sectionClass="sec-title "
                titleClass="title"
                title={"Key Features"}
              />
              <div className="col-lg-6 col-md-12">
                <Widget
                  desc1={
                    AdvanceJava
                      ? AdvanceJava?.body[0].primary.feature1[0].text
                      : "Client-Server architecture"
                  }
                  desc2={
                    AdvanceJava
                      ? AdvanceJava?.body[0].primary.feature2[0].text
                      : "Java Centric"
                  }
                  desc3={
                    AdvanceJava
                      ? AdvanceJava?.body[0].primary.feature3[0].text
                      : "Session Handling"
                  }
                  desc4={
                    AdvanceJava
                      ? AdvanceJava?.body[0].primary.feature4[0].text
                      : "Object relational mapping"
                  }
                />
              </div>
              <div className="col-lg-6 col-md-12">
                <Widget
                  desc1={
                    AdvanceJava
                      ? AdvanceJava?.body[0].primary.feature5[0].text
                      : "Platform Independent"
                  }
                  desc2={
                    AdvanceJava
                      ? AdvanceJava?.body[0].primary.feature6[0].text
                      : "Web and Application Servers"
                  }
                  desc3={
                    AdvanceJava
                      ? AdvanceJava?.body[0].primary.feature7[0].text
                      : "Database Handling"
                  }
                  desc4={
                    AdvanceJava
                      ? AdvanceJava?.body[0].primary.feature8[0].text
                      : "MVC (Model-View-Controller)"
                  }
                />
              </div>
            </div>
          </div>

          <div className="row">
            <h2>Related Courses</h2>
            {AdvanceJava ? advancejavaPrismic : advancejavalocal}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AdvanceJava;
