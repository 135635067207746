
const htmlcss = [
  {
    cardimg: "./img/bootstrap.jpeg",
    title: "Bootstrap",
    cardLink: "/course_bootstrap",
  },
  {
    cardimg: "./img/javascript.jpeg",
    title: "JavaScript",
    cardLink: "/course_javascript",
  },
  {
    cardimg: "./img/angular.png",
    title: "Angular",
    cardLink: "/course_ajax_angular",
  },
];

export default htmlcss;



