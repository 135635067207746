/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Link } from "react-router-dom";

const SiteBreadcrumb = (props: any) => {
  const {
    breadcrumbsClass,
    innerClass,
    titleClass,
    pageTitle,
    parentCategory,
    pageCategory,
    pageName,
    breadcrumbsImg,
  } = props;

  return (
    <div
      className={
        breadcrumbsClass
          ? breadcrumbsClass
          : "rs-breadcrumbs breadcrumbs-overlay"
      }
     
    >
      <a
        href="#"
        className="breadcrumb"
        style={{ backgroundImage: `url(${breadcrumbsImg})` }}
      >
        <div
          className={innerClass ? innerClass : "breadcrumbs-text white-color"}
        >
          <h1 className={titleClass ? titleClass : "page-title"}>
            {pageTitle ? pageTitle : "Breadcrumbs"}
          </h1>
          {pageName ? (
            <ul>
              <li>
                <Link to="/" className="active">
                  {parentCategory ? parentCategory : "Home"}
                </Link>
              </li>
              {pageCategory ? (
                <>
                  <li>
                    <Link to="/" className="active">
                      {pageCategory ? pageCategory : "Category"}
                    </Link>
                  </li>
                  <li>{pageName ? pageName : "Page Name"}</li>
                </>
              ) : (
                <li>{pageName ? pageName : "Page Name"}</li>
              )}
            </ul>
          ) : (
            ""
          )}
        </div>
      </a>
    </div>
  );
};

export default SiteBreadcrumb;
