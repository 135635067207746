const drupalContent = [
    {
        cardimg: "./img/wordpress.jpeg",
        title: "Wordpress",
        cardLink: "/course_wordpress",
      },
      {
        cardimg: "./img/magento.jpeg",
        title: "Magento",
        cardLink: "/course_magento",
      },
]

export default drupalContent