
const phoneContent = [
  {
    cardimg: "./img/android.jpeg",
    title: "Android",
    cardLink: "/course_android",
  },
];

export default phoneContent;
