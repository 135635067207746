import React, { useState } from "react";
import { Link } from "react-router-dom";

function HoverImage({ imageUrl, catLink, title }: any) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ position: "relative", display: "hidden" }}
    >
      <img
        src={imageUrl}
        alt={title}
        style={{ width: "100%", height:"75%", borderRadius: "10px" }}
      />
      <div
        style={{
          position: "absolute",
          bottom: "100%",
          left: "0%",
          width: "100%",
          height: "100%",
          borderRadius: "10px",
          transition: "all 0.3s ease-in-out 2s",
          transform: `translateY(${isHovered ? "100%" : 0})`,
          background: "rgba(0, 0, 0, 0.6)",
          color: "white",
          display: isHovered ? "flex" : "none",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1,
        }}
      >
        <Link to={catLink} className="v-btn">
          View Courses
        </Link>
      </div>
    </div>
  );
}
export default HoverImage;
