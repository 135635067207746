
const BrandContent = [
  {
    imgLink: "/img/1.png",
  },
  {
    imgLink: "/img/2.png",
  },
  {
    imgLink: "/img/3.png",
  },
  {
    imgLink: "/img/4.png",
  },
  {
    imgLink: "/img/5.png",
  },
  {
    imgLink: "/img/6.png",
  },
  {
    imgLink: "/img/7.png",
  },
  {
    imgLink: "/img/8.png",
  },
  {
    imgLink: "/img/9.png",
  },
  {
    imgLink: "/img/10.png",
  },
  {
    imgLink: "/img/11.png",
  },
  {
    imgLink: "/img/12.png",
  },
];

export default BrandContent;
