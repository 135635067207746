
const seleniumContent = [
  {
    cardimg: "./img/manual.jpeg",
    title: "Manual Testing",
    cardLink: "/course_manual_testing",
  },
];

export default seleniumContent;
