
const content = [
  {
    authorImage: "./img/nasar.jpeg",
    name: "Syed Nasar",
    designation: "Software Developer",
    description:
      "“I joined Appsys for Liferay training. It was a good learning experience. The trainer was friendly and cleared the doubts with patience. Appsys trainers are working professionals so we get exposed to real-time scenarios as well. Thanks for providing wonderful training.”",
    place: "Benglore",
  },
  {
    authorImage: "./img/arif.jpeg",
    name: "Arif Meeran",
    designation: "Sr. UI/Frontend developer",
    description:
      "“Fabulous training; trainer has external knowledge on the subject. Also, the course content was effective.”",
    place: "Benglore",
  },
  {
    authorImage: "./img/samad.jpeg",
    name: "Abdul Samad M",
    designation: "Liferay Developer",
    description:
      "“Appsys is providing great atmosphere to learn & shaped you to touch huge heights in your career.”",
    place: "Benglore",
  },
  {
    authorImage: "./img/muzammil.jpeg",
    name: "Muzammil",
    designation: "Software Developer",
    description:
      "“Overall a very informative training. The course content got well covered and also demonstrated the concept very well. Thanks for such informative and concept-clearing training.”",
    place: "Benglore",
  },
  {
    authorImage: "./img/yaseen.jpeg",
    name: "Yaseen Sharief",
    designation: "Managing Director",
    description:
      '"Excellent faulty and Progressive approach! I really appreciate how the educators take practical and theoretical approach while teaching.”',
    place: "Benglore",
  },
  {
    authorImage: "./img/murali.jpeg",
    name: "Murali Kumar",
    designation: "Jr. Software Engineer",
    description:
      '"I have joined v6 appsys infotech, and I took my course JAVALiferay Training. I suggest to you all, This is the best opportunity for starting your career."',
    place: "Benglore",
  },
  {
    authorImage: "./img/aravind.jpeg",
    name: "Aravindhan",
    designation: "Jr. Software Engineer",
    description:
      '"I really like the placement process of appsys, everyone is working really hard for all apprentices so that they could get easily placed in best companies, I was really lucky that I got placed in esquare Software India. Pvt. Ltd. "',
    place: "Benglore",
  },
  {
    authorImage: "./img/jamshith.jpeg",
    name: "Mohamed Jamshith",
    designation: "Jr. Software Engineer",
    description:
      '" I have joined appsys for training. I will always be grateful to them for providing me a platform of practical learning and preparing me for corporate life. "',
    place: "Benglore",
  },
  {
    authorImage: "./img/paramesh.jpeg",
    name: "Parameshwaran",
    designation: "Jr. Software Engineer",
    description:
      '"Appsys is the best place to Learn JAVA And Liferay with job Assurance. If you want to start your career join Appsys. " ',
    place: "Benglore",
  },
  {
    authorImage: "./img/fahad.jpeg",
    name: "Syed Abrar Fahad",
    designation: "Jr. Software Engineer",
    description:
      '"Appsys is a great place to build a good carrier in the IT field. Recommended place to join with on job training..!!"',
    place: "Benglore",
  },
  {
    authorImage: "./img/mytheen.jpeg",
    name: "Nagoor Mytheen",
    designation: "Jr. Software Engineer",
    description:
      '"The experience at Appsys was enriching in terms of technical skills and live project training. I have learned a lot of basics of JAVA and Liferay development."',
    place: "Benglore",
  },
  {
    authorImage: "./img/gayathri.png",
    name: "Gayathri",
    designation: "Software Engineer",
    description:
      '"Excellent and Progressive approach! I really appreciate how the trainers take a practical and theoretical approach while teaching. The information was accurate, through and was addressed in an elementary way.”',
    place: "Benglore",
  },
  {
    authorImage: "./img/gayathri.png",
    name: "Razeena",
    designation: "Software Engineer",
    description:
      "“I found the trainer excellent and could answer all questions asked and delivered the course in an interesting format”.",
    place: "Benglore",
  },
  {
    authorImage: "./img/gayathri.png",
    name: "Rajkumari Prabaharan",
    designation: "Software Engineer",
    description:
      "“Excellent trainer, obviously an expert in his field; practical sessions really helped.”",
    place: "Benglore",
  },
  {
    authorImage: "./img/basvan.jpeg",
    name: "Basvan Khan",
    designation: "Software Engineer",
    description:
      '"Because of Appsys, I was able to land a new technical role at my dream company."',
    place: "Benglore",
  },
  {
    authorImage: "./img/thaha.jpeg",
    name: "Mahammad Taha",
    designation: "Liferay Portal Programmer",
    description:
      '"Appsys helped me find a better way of thinking that helps in every aspect of my life, and I got a new job."',
    place: "Benglore",
  },
  {
    authorImage: "./img/sajjad.jpeg",
    name: "Sajjad Khan",
    designation: "Senior Software Developer",
    description:
      "“An excellent place to enhance yourself. I learnt a lot, I am inspired to rethink the way I plan and present my work.”",
    place: "Benglore",
  },
  {
    authorImage: "./img/angu.jpeg",
    name: "Angu Angumani",
    designation: "Jr.s/w Enginner",
    description:
      "Excellent Course explained in a short and concise manner. Strongly recommended.”",
    place: "Benglore",
  },
  {
    authorImage: "./img/saleem.jpeg",
    name: "Mohamed Saleem",
    designation: "Senior Application Analyst",
    description:
      "“The whole training session was good, it was full of knowledge. An interactive training session, which i enjoyed a lot and gained lot of knowledge.”",
    place: "Benglore",
  },
  {
    authorImage: "./img/mufas.jpeg",
    name: "Mufas Mohammed Mydeen",
    designation: "Liferay Develepor",
    description:
      "“This is not only a great place to begin any career, it is also a wonderful place to develop a successful career.”",
    place: "Benglore",
  },
];
export default content;
