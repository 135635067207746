const MobileAppContent = [
  {
    cardimg: "./img/phonegap.jpeg",
    title: "PhoneGap",
    cardLink: "/course_phonegap",
  },
  {
    cardimg: "./img/android.jpeg",
    title: "Android",
    cardLink: "/course_android",
  },
];
export default MobileAppContent;
