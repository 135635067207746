/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

const Card = (props:any) => {
const{cardimg , title ,cardLink}=props;
  return (
    <React.Fragment>
      <div className="card" style={{ width: "20rem",height:"25rem"}}>
        <img style={{ maxHeight: "12rem",minHeight:"12rem" , padding:"10px"}}
          className="card-img-top"
          src={cardimg}
          alt="Card image cap"
        />
        <div className="card-body text-center" >
          <h4 className="card-title" style={{padding:"10px",height:"80px"}}>{title}</h4>

          <a href={cardLink} className="view_button ">
            View course
          </a>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Card;
