import React from "react";

const Widget = (props: any) => {
  const { desc1, desc2, desc3, desc4, desc5 } = props;
  return (
    <div className="widget-archives">
      <ul>
        <li>
          {desc1 && (
            <div>
              <i className="fa fa-angle-double-right pr-5"></i>
              {desc1}
            </div>
          )}
        </li>
        <li>
          {desc2 && (
            <div>
              <i className="fa fa-angle-double-right pr-5"></i>
              {desc2}
            </div>
          )}
        </li>
        <li>
          {desc3 && (
            <div>
              <i className="fa fa-angle-double-right pr-5"></i>
              {desc3}
            </div>
          )}
        </li>
        <li>
          {desc4 && (
            <div>
              <i className="fa fa-angle-double-right pr-5"></i>
              {desc4}
            </div>
          )}
        </li>
        <li>
          {desc5 && (
            <div>
              <i className="fa fa-angle-double-right pr-5"></i>
              {desc4}
            </div>
          )}
        </li>
      </ul>
    </div>
  );
};

export default Widget;
