import { useEffect } from "react";
import SectionTitle from "../../Components/Section/SectionTitle";
import AOS from "aos";
import courseContent from "../../Components/contents/courseContent";
import HoverImage from "../../Components/Section/CourseSingle";

const Courses = (props: any) => {
  useEffect(() => {
    AOS.init();
  });
  const { courseSlice } = props;
  const prismicCourse = courseSlice?.body[3].items.map(
    (item: any, index: number) => {
      return (
        <div className="col-lg-4 col-md-6 mb-30" key={index}>
          <div
            data-aos={item.data_aos[0].text}
            data-aos-duration="1200"
            data-aos-delay="150"
          >
            <HoverImage
              className="subject-wrap mr-60"
              imageUrl={item.course_img.url}
              title={item.course_title[0].text}
              catLink={item.link[0].text}
            />
          </div>
        </div>
      );
    }
  );

  const CoursePage = courseContent.map((data, i) => {
    return (
      <div className="col-lg-4 col-md-6 mb-30" key={i}>
        <div
          data-aos={data.dataAos}
          data-aos-duration="1200"
          data-aos-delay="150"
        >
          <HoverImage
            className="subject-wrap mr-60"
            imageUrl={data.cardImg}
            title={data.title}
            catLink={data.catLink}
          />
        </div>
      </div>
    );
  });
  return (
    <div
      className="rs-subject style1 pt-90 pb-50 md-pt-64 md-pb-40"
      id="course"
    >
      <div className="container">
        <div data-aos="zoom-in" data-aos-duration="1200" data-aos-delay="150">
          <SectionTitle
            sectionClass="sec-title mb-60 text-center md-mb-30"
            titleClass="title mb-0"
            title={
              courseSlice
                ? courseSlice?.body[3].primary.title[0].text
                : "EXPLORE COURSES"
            }
            subtitleClass="sub-title primary"
            subtitle={
              courseSlice
                ? courseSlice?.body[3].primary.subtitle[0].text
                : "Get the World Class Courses"
            }
          />
        </div>

        <div className="row ml-lg-50 mr-lg-50 h-50">
          {courseSlice ? prismicCourse : CoursePage}
        </div>
      </div>
    </div>
  );
};

export default Courses;
