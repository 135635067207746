import React, { useEffect } from "react";
import SingleTestimonial from "../../Components/Section/SingleTestinmonial";
import SiteBreadcrumb from "../../Components/Breadcumb/breadcumb";
import content from "../../Components/contents/testimonialContent";
import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { Helmet } from "react-helmet";

const Testimonials = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const [document] = useAllPrismicDocumentsByType("testimonials_type");
  const testimonialPageContent = document && document[0]?.data;
  console.log(testimonialPageContent);

  const multiTestimonials = content.map((data, i) => {
    return (
      <div className="col-lg-4 col-md-12 mb-30" key={i}>
        <SingleTestimonial
          itemClass="testi-item"
          authorImage={data.authorImage}
          name={data.name}
          designation={data.designation}
          description={data.description}
          place={data.place}
        />
      </div>
    );
  });

  const prismicContent = testimonialPageContent?.body[0].items.map(
    (items: any, index: number) => {
      return (
        <div className="col-lg-4 col-md-12 mb-30" key={index}>
          <SingleTestimonial
            itemClass="testi-item"
            authorImage={items.authorimage.url}
            name={items.name[0].text}
            designation={items.designation[0].text}
            description={items.description[0].text}
            place={items.place[0].text}
          />
        </div>
      );
    }
  );

  return (
    <div className="rs-testimonial style4  pb-50  md-pb-70">
       <Helmet>
        <title>Placement - www.appsysit.com</title>
       
      </Helmet>
      <SiteBreadcrumb
        parentCategory="Home"
        pageTitle={
          testimonialPageContent &&
          testimonialPageContent.body[0].primary.title[0].text
        }
        pageName="Testimonials"
        breadcrumbsImg={"./bg/bgcontact.jpeg"}
      />
      <div className="container">
        <div className="row mt-20">
          {testimonialPageContent ? prismicContent : multiTestimonials}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
