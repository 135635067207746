import { Link } from "react-router-dom";

const SingleTestimonial = (props: any) => {
  const {
    itemClass,
    description,
    name,
    authorLink,
    designation,
    place,
    authorImage,
  } = props;
  return (
    <div className={itemClass}>
      <div className="row no-gutter"  style={{height:"425px"}}>
        <div className="col text-center">
          <div className="user-img">
            <img src={authorImage} alt={name} />
            <br />
            <Link className="name" to={authorLink}>
              {name}
            </Link>
            <br />
            <span className="designation">{designation}</span>
            <div className="place">{place}</div>
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="user-info">
            <span className="quote">
              <i className="flaticon-phrase"></i>
            </span>
            <div className="desc text-justify">{description}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleTestimonial;
