
const JavaContent = [
  {
    cardimg: "./img/corejava.jpeg",
    title: "Core Java",
    cardLink: "/course_core_java",
  },
  {
    cardimg: "./img/advancejava.jpeg",
    title: "advance java",
    cardLink: "/course_advance_java",
  }
];

export default JavaContent;
