/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
import Newsletter from "./Newsletter";

const FooterStyleTwo = (props: any) => {
  const { footerClass, footerTopClass, footerLogo, footerBG, footerColor } =
    props;

  const footerBGStyle = {
    backgroundImage: `url(${footerBG})`,
    backgroundColor: `url(${footerColor})`,
  };
  const defaultBGStyle = {
    backgroundImage: `url(${"defaultBG"})`,
    backgroundColor: "#273c66",
  };

  return (
    <>
      <Newsletter />
      <footer
        id="rs-footer"
        className={footerClass ? footerClass : "rs-footer"}
        style={footerBG && footerColor ? footerBGStyle : defaultBGStyle}
      >
        <div className="container">
          <div className={footerTopClass ? footerTopClass : "footer-top"}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-4 col-md-12 col-sm-12 pb-70  md-mb-20 ">
                  <div
                    className="light-logo"
                    style={{ padding: "10px 80px 40px 40px" }}
                  >
                    <Link to="/">
                      <img
                        src={footerLogo ? footerLogo : "./article.png"}
                        alt="Logo"
                      />
                    </Link>
                  </div>
                  <hr style={{ color: "white" }} />

                  <h4 className="widget-title">MEDIA SUPPORT</h4>
                  <ul className="media">
                    <li>
                      <a
                        href="https://www.linkedin.com/company/appsys-info-tech"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/appsysit"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://instagram.com/appsysinfotech"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://wa.me/9538093537"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-whatsapp"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/appsysit"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12  md-mb-50">
                  <h4 className="widget-title">Information</h4>
                  <ul className="site-map lg-mx-1px">
                    <li>
                      <i className="fa fa-angle-double-right pr-10 text-white"></i>
                      <a href="/#about">About Us</a>
                    </li>
                    <li>
                      <i className="fa fa-angle-double-right pr-10 text-white"></i>
                      <a href="/#whyappsys">Why Appsys</a>
                    </li>
                    <li>
                      <i className="fa fa-angle-double-right pr-10 text-white"></i>
                      <a href="/#course">Courses</a>
                    </li>
                    <li>
                      <i className="fa fa-angle-double-right pr-10 text-white"></i>
                      <a href="/#testimonial">Testimonial</a>
                    </li>
                    <li>
                      <i className="fa fa-angle-double-right pr-10 text-white"></i>
                      <a href="/contact">Contact Us</a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12  md-mb-50">
                  <h4 className="widget-title">CONTACT</h4>
                  <ul className="address-widget address2">
                    <li>
                      <i className="flaticon-location"></i>
                      <div className="desc">
                        P04, 4th Floor, Gold Signature, 95, Jammu & Kashmir
                        Bank, Frazer Town, Bangalore -560005
                      </div>
                    </li>
                    <li>
                      <i className="flaticon-call"></i>
                      <div className="desc">
                        <a href="tel: +91 9071 271 271">M: +91 9071 271 271</a>
                        <a href="tel:+91 9740 193 765">M: +91 9740 193 765</a>
                      </div>
                    </li>
                    <li>
                      <i className="flaticon-email"></i>
                      <div className="desc">
                        <a href="mailto:infoname@gmail.com">
                          enquiry@appsysit.com
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="container">
            <div className="row y-middle">
              <div className="col-lg-8 md-mb-20">
                <div className="copyright text-center md-text-left">
                  <p>© 2023 All Rights Reserved. Appsys Infotech Pvt Ltd</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterStyleTwo;
