import React, { useEffect } from "react";
import Pagebreadcumb from "../../../Components/Breadcumb/pagebreadcumb";
import SectionTitle from "../../../Components/Section/SectionTitle";
import { usePrismicDocumentByUID } from "@prismicio/react";
import manualtestContent from "../courseContents/manualtestContent";
import Widget from "../../../Components/Section/widget";
import DegreeSingle from "../../../Components/card/courseCard";
import { Helmet } from "react-helmet";

const ManualTest = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const [document] = usePrismicDocumentByUID(
    "innercourse_type",
    "manualtestid"
  );
  const manualtest = document && document?.data;
  console.log(manualtest);

  const manualtestPrismic = manualtest?.body[0]?.items.map(
    (item: any, index: number) => {
      return (
        <div className="col-lg-3 col-md-6" key={index}>
          <DegreeSingle
            cardimg={item.cardimg.url}
            title={item.card_title[0].text}
            cardLink={item.link[0].text}
          />
        </div>
      );
    }
  );

  const manualtestlocal = manualtestContent.map((data, i) => {
    return (
      <div className="col-lg-3 col-md-6" key={i}>
        <DegreeSingle
          cardimg={data.cardimg}
          title={data.title}
          cardLink={data.cardLink}
        />
      </div>
    );
  });
  return (
    <React.Fragment>
      <Helmet>
        <title>ManualTesting - www.appsysit.com</title>
      </Helmet>
      <div className="rs-degree style1 modify pb-70  md-pb-40">
        <Pagebreadcumb
          title={
            manualtest
              ? manualtest?.body[0].primary.course_title[0].text
              : "Manual Testing"
          }
          description={
            manualtest
              ? manualtest?.body[0].primary.course_desc[0].text
              : "Our core Java course is designed for students and working professionals. It is an object-oriented, class-based, concurrent, secured and general-purpose computer-programming language. It is a widely used robust technology"
          }
          bgimg={
            manualtest
              ? manualtest?.body[0].primary.topimg.url
              : "./bg/bgMtesting.png"
          }
        />
        <div className="container">
          <div className="row">
            <div className="col pt-50">
              <SectionTitle
                sectionClass="sec-title "
                titleClass="title"
                title={
                  manualtest
                    ? manualtest?.body[0].primary.course_overview[0].text
                    : "Overview"
                }
                descClass="desc"
                description={
                  manualtest
                    ? manualtest?.body[0].primary.overview_desc[0].text
                    : "AppSys is a leading training institution in Bangalore. We are specialized in giving excellent training courses in various technologies especially in Liferay and Java. We have industry leaders in Liferay and Java who are working in top MNC Companies. The course structure is designed after an intensive research and vast experience in various specialized skills to get the developers market ready. We also provide 100% real time scenarios in all the courses. With our real time trainer’s expertise and tailor-made training curriculum you can meet your career objectives whether you are a student, fresher or a working professional. We admit that your career must be built to your own specifications, that’s why our training target on 1-to-1 solutions, based on training courses from top Developers and Trainers."
                }
              />
            </div>
            <div className="row">
              <SectionTitle
                sectionClass="sec-title "
                titleClass="title"
                title={
                  manualtest
                    ? manualtest?.body[0].primary.course_feature[0].text
                    : "Key Features"
                }
              />
              <div className="col-lg-6 col-md-12">
                <Widget
                  desc1={
                    manualtest
                      ? manualtest?.body[0].primary.feature1[0].text
                      : "Client-Server architecture"
                  }
                  desc2={
                    manualtest
                      ? manualtest?.body[0].primary.feature2[0].text
                      : "Java Centric"
                  }
                  desc3={
                    manualtest
                      ? manualtest?.body[0].primary.feature3[0].text
                      : "Session Handling"
                  }
                />
              </div>
              <div className="col-lg-6 col-md-12">
                <Widget
                  desc1={
                    manualtest
                      ? manualtest?.body[0].primary.feature4[0].text
                      : "Platform Independent"
                  }
                  desc2={
                    manualtest
                      ? manualtest?.body[0].primary.feature5[0].text
                      : "Web and Application Servers"
                  }
                  desc3={
                    manualtest
                      ? manualtest?.body[0].primary.feature6[0].text
                      : "Database Handling"
                  }
                />
              </div>
            </div>

            <div className="row pt-50">
              <h2>
                {manualtest
                  ? manualtest?.body[0].primary.course_related[0].text
                  : "Related Courses"}
              </h2>
              {manualtest ? manualtestPrismic : manualtestlocal}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ManualTest;
