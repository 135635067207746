
const liferayuiContent = [
  {
    cardimg: "./img/liferay3.png",
    title: "Liferay Developer",
    cardLink: "/course_liferay_developer",
  },
  {
    cardimg: "./img/liferay1.png",
    title: "Liferay Portal",
    cardLink: "/course_liferay_portal",
  },
];

export default liferayuiContent;
