const angularContent = [
  {
    cardimg: "./img/html.jpeg",
    title: "Html & css",
    cardLink: "/course_html_css",
  },
  {
    cardimg: "./img/bootstrap.jpeg",
    title: "Bootstrap",
    cardLink: "/course_bootstrap",
  },
  {
    cardimg: "./img/javascript.jpeg",
    title: "JavaScript",
    cardLink: "/course_javascript",
  },
];

export default angularContent;
