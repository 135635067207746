const TestingContent = [
  {
    cardimg: "./img/manual.jpeg",
    title: "Manual Testing",
    cardLink: "/course_manual_testing",
  },
  {
    cardimg: "./img/selenium.jpeg",
    title: "Selenium Testing",
    cardLink: "/course_selenium",
  },
];

export default TestingContent;
