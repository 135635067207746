import React from "react";
import SectionTitle from "../../Components/Section/SectionTitle";
import SingleTestimonial from "../../Components/Section/SingleTestinmonial";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import content from "../../Components/contents/testimonialContent";
import { animateScroll as scroll } from 'react-scroll';

const Testimonial = (props: any) => {
  const { testimonialSlice } = props;

  const testimonialSettings = {
    dots: false,
    centerMode: false,
    infinite: true,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,

    responsive: [

      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          dots: false,
        },
      },
    ],
  };
  const bgStyle = {
    backgroundImage: `url(${"slice && slice.body[0].primary.bgimg.url"})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
  };

  const handleClick = () => {
    scroll.scrollToTop();
  };

  const prismicTestimonial = testimonialSlice?.body[5]?.items.map(
    (item: any, index: number) => {
      return (
        <SingleTestimonial
          key={index}
          itemClass="testi-item"
          authorImage={item.authorimage.url}
          name={item.name[0].text}
          designation={item.designation[0].text}
          description={item.description[0].text}
          place={item.place[0].text}
        />
      );
    }
  );

  const testimonialContent = content.map((data, i) => {
    return (
      <SingleTestimonial
        key={i}
        itemClass="testi-item"
        authorImage={data.authorImage}
        name={data.name}
        designation={data.designation}
        description={data.description}
        place={data.place}
      />
    );
  });

  return (
    <React.Fragment>
      <div
        className="rs-popular-courses style2 pt-60 pb-50 md-pt-24 md-pb-30"
        id="testimonial"
        style={bgStyle}
      >
        <div className="rs-testimonial style4 pt-30 pb-50 md-pt-15 md-pb-15">
          <div className="container">
            <SectionTitle
              sectionClass="sec-title mb-60 text-center md-mb-30"
              titleClass="title mb-0"
              title={
                testimonialSlice
                  ? testimonialSlice?.body[5]?.primary.title[0].text
                  : "Testimonial"
              }
            />
            <div className="row">
              <Slider {...testimonialSettings}>
                {testimonialSlice ? prismicTestimonial : testimonialContent}
              </Slider>
              <Link to="/testimonial" className="view_btn">
                <button className="view_button" onClick={handleClick}>View All ...</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Testimonial;
