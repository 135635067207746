import React, { useEffect } from "react";
import SiteBreadcrumb from "../../../Components/Breadcumb/breadcumb";
import Card from "../../../Components/card/Card";
import { useAllPrismicDocumentsByType } from "@prismicio/react";
import PhpContent from "../courseContents/PhpContent";
import { Helmet } from "react-helmet";

const Php = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const [document] = useAllPrismicDocumentsByType("course_type");
  const courseContent = document && document[0]?.data;

  const prismicPhp = courseContent?.body[2]?.items.map(
    (item: any, index: number) => {
      return (
        <div className="col-lg-3 col-md-6 mb-30" key={index}>
          <Card
            cardimg={item.course_img.url}
            title={item.course_title[0].text}
            cardLink={item.linkurl[0].text}
          />
        </div>
      );
    }
  );

  const phpPage = PhpContent.map((data, i) => {
    return (
      <div className="col-lg-3 col-md-6 mb-30" key={i}>
        <Card
          cardimg={data.cardimg}
          title={data.title}
          cardLink={data.cardLink}
        />
      </div>
    );
  });

  return (
    <React.Fragment>
       <Helmet>
        <title>PHP courses - www.appsysit.com</title>
       
      </Helmet>
      <SiteBreadcrumb
        pageTitle={
          courseContent ? courseContent?.body[2].primary.title[0].text : "PHP"
        }
        breadcrumbsImg={
          courseContent
            ? courseContent?.body[2].primary.bgimg.url
            : "./bg/bgphp.jpeg"
        }
      />
      <div className="rs-faq-part style1 pt-50 pb-80 md-pt-70 md-pb-80">
        <div className="container">
          <div className="row ">{courseContent ? prismicPhp : phpPage}</div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Php;
