
const UiuxContent = [
  {
    cardimg: "./img/html.jpeg",
    title: "HTML&CSS",
    cardLink: "/course_html_css",
  },
  {
    cardimg: "./img/bootstrap.jpeg",
    title: "Bootstrap",
    cardLink: "/course_bootstrap",
  },
  {
    cardimg: "./img/javascript.jpeg",
    title: "JavaScript",
    cardLink: "/course_javascript",
  },
  {
    cardimg: "./img/angular.png",
    title: "Ajax & Angular",
    cardLink: "/course_ajax_angular",
  },
];

export default UiuxContent;
