import { useState } from "react";
import { Link } from "react-router-dom";

const RSMobileMenu = (props: any) => {
  const { menuOpen, headerFullWidth, setMenuOpen } = props;

  const [home, setHome] = useState(false);
  const [about, setAbout] = useState(false);
  const [course, setCourse] = useState(false);
  const [testimonial, setTestimonial] = useState(false);
  const [placement, setPlacement] = useState(false);
  const [contact, setcontact] = useState(false);

  const openMobileMenu = (menu: any) => {
    if (menu === "home") {
      setHome(!home);
      setAbout(false);
      setCourse(false);
      setTestimonial(false);
      setPlacement(false);
      setcontact(false);
    } else if (menu === "about") {
      setHome(false);
      setAbout(!about);
      setCourse(false);
      setTestimonial(false);
      setPlacement(false);
      setcontact(false);
    } else if (menu === "course") {
      setHome(false);
      setAbout(false);
      setCourse(!course);
      setTestimonial(false);
      setPlacement(false);
      setcontact(false);
    } else if (menu === "testimonial") {
      setHome(false);
      setAbout(false);
      setCourse(false);
      setTestimonial(!testimonial);
      setPlacement(false);
      setcontact(false);
    } else if (menu === "placement") {
      setHome(false);
      setAbout(false);
      setCourse(false);
      setTestimonial(false);
      setPlacement(!placement);
      setcontact(false);
    } else if (menu === "contact") {
      setHome(false);
      setAbout(false);
      setCourse(false);
      setTestimonial(false);
      setPlacement(false);
      setcontact(!contact);
    }
  };
  return (
    <div
      className={
        headerFullWidth ? "container-fluid relative" : "container relative"
      }
    >
      <div className={menuOpen ? "mobile-menu-part open" : "mobile-menu-part"}>
        <div className="mobile-menu">
          <ul className="nav-menu">
            <li className={home ? " current-menu-item" : ""}>
              <Link
                to="/home"
                onClick={() => {
                  openMobileMenu("home");
                  setMenuOpen(false);
                }}
              >
                Home
              </Link>
            </li>
            <li className={about ? " current-menu-item" : ""}>
              <a
                href="/#about"
                onClick={() => {
                  openMobileMenu("about");
                  setMenuOpen(false);
                }}
              >
                about us
              </a>
            </li>
            <li className={course ? " current-menu-item" : ""}>
              <a
                href="/#course"
                onClick={() => {
                  openMobileMenu("course");
                  setMenuOpen(false);
                }}
              >
                courses
              </a>
            </li>
            <li className={testimonial ? " current-menu-item" : ""}>
              <a
                href="/#testimonial"
                onClick={() => {
                  openMobileMenu("Testimonial");
                  setMenuOpen(false);
                }}
              >
                Testimonial
              </a>
            </li>
            <li className={placement ? " current-menu-item" : ""}>
              <Link
                to="/placement"
                onClick={() => {
                  openMobileMenu("placement");
                  setMenuOpen(false);
                }}
              >
                Placement
              </Link>
            </li>
            <li className={contact ? " current-menu-item" : ""}>
              <Link
                to="/contact"
                onClick={() => {
                  openMobileMenu("contact");
                  setMenuOpen(false);
                }}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RSMobileMenu;
