const liferayContent = [
  {
    cardimg: "./img/liferay1.png",
    title: "Liferay Portal",
    cardLink: "/course_liferay_portal",
  },
  {
    cardimg: "./img/liferay2.jpeg",
    title: "Liferay UI Development",
    cardLink: "/course_liferay_ui_developement",
  },
  {
    cardimg: "./img/liferay3.png",
    title: "Liferay Developer",
    cardLink: "/course_liferay_developer",
  },
];

export default liferayContent;
