
const manualContent = [
  {
    cardimg: "./img/selenium.jpeg",
    title: "Selenium",
    cardLink: "/course_selenium",
  },
];

export default manualContent;
