import React from "react";
import { Link } from "react-router-dom";

const CourseSingleThree = (props: any) => {
  const {
    courseClass,
    subTitle1,
    subTitle2,
    courseList1,
    courseList2,
    courseList3,
    courseList4,
    courseList5,
    courseTitle,
    Button,
    studentQuantity,
    applyLink,
  } = props;

  return (
    <div className={courseClass} style={{ height: "320px", width: "300px" }}>
      <div className="front-part">
        <div className="content-part">
          <h2
            className="categorie fw-bold mb-70 mt-20"
            style={{ border: "2px solid black", borderRadius: "50px", padding:"10px"}}
          >
            {courseTitle}
          </h2>
          <h5 className="title">{subTitle1}</h5>
          <h5 className="title">{subTitle2}</h5>
        </div>
      </div>
      <div className="inner-part">
        <div className="content-part">
          <h6 className="title">{courseList1}</h6>
          <h6 className="title">{courseList2}</h6>
          <h6 className="title">{courseList3}</h6>
          <h6 className="title">{courseList4}</h6>
          <h6 className="title">{courseList5}</h6>

          <ul className="course-meta">
            <li className="course-user">{studentQuantity}</li>
          </ul>
        </div>
      </div>
      <div className="price-btn">
        <Link to={applyLink}>
          {Button} <i className="fa fa-arrow-right"></i>
        </Link>
      </div>
    </div>
  );
};

export default CourseSingleThree;
