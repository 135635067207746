
const bannerContent = [
  { bgimg: "./img/banner1.jpeg", title: "Get the world-class courses." },

  { bgimg: "./img/banner2.jpeg", title: "Assured Job Training" },

  { bgimg: "./img/banner3.jpeg", title: "Internship for Final Year Students"},
];

export default bannerContent;
