
const CarrierContent = [
  {
    courseClass: "course-wrap",
    courseTitle: "JAVA / LIFERAY",
    subTitle1: "Job Guarantee Program",
    subTitle2: "Day One Offer letter",
    courseList1: "Course Detail: Liferay",
    courseList2: "Course Duration: 130 Days",
    courseList3: "Experience On Live Projects",
    courseList4: "No Bond No False Comitments",
    courseList5: "100% Guaranteed Placements",
    Button: "Apply Here",
    applyLink: "/contact",
    dataAos:"fade-up",
  },
  {
    courseClass: "course-wrap",
    courseTitle: "PHP",
    subTitle1: "Job Guarantee Program",
    subTitle2: "Day One Offer letter",
    courseList1: "Course Detail: Liferay",
    courseList2: "Course Duration: 130 Days",
    courseList3: "Experience On Live Projects",
    courseList4: "No Bond No False Comitments",
    courseList5: "100% Guaranteed Placements",
    Button: "Apply Here",
    applyLink: "/contact",
    dataAos:"fade-down",
  },
  {
    courseClass: "course-wrap",
    courseTitle: "Course Offered",
    subTitle1: "Job Guarantee Program",
    subTitle2: "Day One Offer letter",
    courseList1: "Course Detail: Liferay",
    courseList2: "Course Duration: 130 Days",
    courseList3: "Experience On Live Projects",
    courseList4: "No Bond No False Comitments",
    courseList5: "100% Guaranteed Placements",
    Button: "Apply Here",
    applyLink: "/contact",
    dataAos:"fade-up",
  },
];

export default CarrierContent;
