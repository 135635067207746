import React from "react";

const WhyCard = (props: any) => {
  const { icon, title, desc } = props;
  return (
    <div className="events-short-top mb-30 align-items-start">
      <div className="row justify-content-center">
        <div className="col-4 mb-20">
          <div className="icon">
            <i className={icon}></i>
          </div>
        </div>
        <div className="content-part">
          <h3 className="title text-center">{title}</h3>
          <p className="txt pl-0">{desc}</p>
        </div>
      </div>
    </div>
  );
};

export default WhyCard;
