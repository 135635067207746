import React, { useEffect } from "react";
import SectionTitle from "../../Components/Section/SectionTitle";
import WhyCard from "../../Components/card/whyCard";
import WhyAppsysContent from "../../Components/contents/whyAppsysContent";
import AOS from "aos";

const bgStyle = {
  backgroundImage: `url(${"./img/bg-4.png"})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
};

const Whyappsys = (props: any) => {
  useEffect(() => {
    AOS.init();
  });

  const { whyapsysSlice } = props;

  const prismicAppsys = whyapsysSlice?.body[4].items.map(
    (item: any, index: number) => {
      return (
        <div className="col-lg-4 col-md-6" key={index}>
          <div
            data-aos={item.dataaos[0].text}
            data-aos-duration="1200"
            data-aos-delay="150"
          >
            <WhyCard
              icon={item.icon[0].text}
              title={item.why_title[0].text}
              desc={item.why_description[0].text}
            />
          </div>
        </div>
      );
    }
  );

  const appsysitContent = WhyAppsysContent.map((data, i) => {
    return (
      <div className="col-lg-4 col-md-6" key={i}>
        <div
          data-aos={data.dataAos}
          data-aos-duration="1200"
          data-aos-delay="150"
        >
          <WhyCard icon={data.icon} title={data.title} desc={data.desc} />
        </div>
      </div>
    );
  });
  return (
    <React.Fragment>
      <div style={bgStyle}>
        <div
          id="whyappsys"
          className="rs-blog home9-style event2-bg pt-70 pb-50 md-pt-24 md-pb-30"
        >
          <div className="container">
            <SectionTitle
              sectionClass="sec-title mb-60 md-mb-30"
              descriptionClass="desc text-justify"
              description={
                whyapsysSlice
                  ? whyapsysSlice?.body[4]?.primary.description[0].text
                  : "Appsys Info tech is a training and placement institute which trains candidates in Niche Technology and guarantees 100% placements. We also provide on-hand experience on live projects. We at Appsys do not make any false promises or commitments. More info you can check here."
              }
              titleClass="title black-color text-center"
              title={
                whyapsysSlice
                  ? whyapsysSlice?.body[4]?.primary.title[0].text
                  : "Why Appsys"
              }
            />
            <div className="row">
              {whyapsysSlice ? prismicAppsys : appsysitContent}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Whyappsys;
