
const corejavaContent = [
  {
    cardimg: "./img/advancejava.jpeg",
    title: "Advanced Java",
    cardLink: "/course_advance_java",
  },
  {
    cardimg: "./img/liferay1.png",
    title: "Liferay Portal",
    cardLink: "/course_liferay_portal",
  },
];

export default corejavaContent;
