const bootstrap =[
    {
        cardimg: "./img/html.jpeg",
        title: "Html & css",
        cardLink: "/course_html_css",
      },
      {
        cardimg: "./img/javascript.jpeg",
        title: "JavaScript",
        cardLink: "/course_javascript",
      },
      {
        cardimg: "./img/angular.png",
        title: "Angular",
        cardLink: "/course_ajax_angular",
      },
]

export default bootstrap