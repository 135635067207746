import React from "react";

const pagebreadcumb = (props: any) => {
  const { title, description, bgimg } = props;
  return (
    <React.Fragment>
      <div
        className="row "
        style={{
          background: "linear-gradient(rgb(63, 136, 182), rgb(43, 86, 134))",
          color: "#fff",
          
        }}
      >
        <div className="col-lg-8 col-md-12 pt-110 pl-100 md-pt-30 md-pl-30 pr-20 md-text-center">
          <h2 style={{color:"#fff", marginTop:"25px"}}>{title}</h2>
          <p>{description}</p>
        </div>
        <div className="col-4 d-none d-lg-block">
          <img src={bgimg} alt="" />
        </div>
      </div>
    </React.Fragment>
  );
};

export default pagebreadcumb;
