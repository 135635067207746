import React, { useEffect, useState } from "react";
import SectionTitle from "../../Components/Section/SectionTitle";
import SiteBreadcrumb from "../../Components/Breadcumb/breadcumb";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";
import ContactForm from "../Contact/ContactForm";
import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Helmet } from "react-helmet";

const Placement = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const [document] = useAllPrismicDocumentsByType("placement_type");
  const placementPageContent = document && document[0]?.data;
  console.log(placementPageContent);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const prismicContent = placementPageContent?.body[0].items.map(
    (items: any, index: number) => {
      return (
        <Accordion className="accordion-style1" allowZeroExpanded key={index}>
          <AccordionItem className="accordion-item" uuid="a">
            <AccordionItemHeading className="card-header">
              <AccordionItemButton className="card-link">
                {items.opening_title[0].text}
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="card-body">
              Requieement: {items.opening_requirement[0].text}
              <br />
              Location: {items.opening_location[0].text}
              <br />
              Experience: {items.opening_experience[0].text}
              <br />
              Positions: {items.no_of_positions[0].text}
              <br />
              Required skill: {items.required_skill[0].text}
              <br />
              <Link className="apply_btn" to="">
                <button className="apply_button" onClick={handleShow}>
                  Apply Now
                </button>
              </Link>
              <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  <div className="rs-contact style1  pt-30 md-pt-20 pb-50 md-pb-80">
                    <div className="container">
                      <div className="contact-widget">
                        <ContactForm
                          submitBtnClass="btn-send"
                          btnText="Submit"
                          text={
                            <>
                              Interested candidates please send your resume to
                              <Link to="#">placement@appsysit.com</Link>
                            </>
                          }
                          fieldRequired={
                            <input
                              className="from-control"
                              type="text"
                              id="name"
                              name="name"
                              placeholder="Experience (in Years)"
                              required
                            />
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      );
    }
  );

  return (
    <React.Fragment>
      <Helmet>
        <title>Placement - www.appsysit.com</title>
       
      </Helmet>
      <SiteBreadcrumb
        pageTitle={
          placementPageContent
            ? placementPageContent?.body[0]?.primary?.title[0].text
            : "Placement"
        }
        pageName={
          placementPageContent
            ? placementPageContent?.body[0]?.primary?.title[0].text
            : "Placement"
        }
        breadcrumbsImg={
          placementPageContent
            ? placementPageContent?.body[0]?.primary?.bgimg.url
            : "./bg/bgplacement.jpeg"
        }
      />

      <div className="rs-contact style1 event-bg pt-30 md-pt-20 pb-50 md-pb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 md-mb-30">
              <div className="rs-faq-part style1 pb-50 md-pt-20 md-pb-80">
                <div className="container">
                  <div className="main-part innerpage">
                    <div className="faq-content mb-50 md-mb-30">
                      <SectionTitle
                        sectionClass="sec-title3 mb-36"
                        title={
                          placementPageContent
                            ? placementPageContent?.body[0]?.primary
                                ?.section_title2[0].text
                            : "Current Openings"
                        }
                      />
                      {prismicContent}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 pl-60 md-pl-14">
              <div className="contact-widget">
                <SectionTitle
                  sectionClass="sec-title3 mb-36"
                  title={
                    placementPageContent
                      ? placementPageContent?.body[0]?.primary
                          ?.section_title1[0].text
                      : "Apply for Job"
                  }
                />
                <ContactForm
                  submitBtnClass="btn-send bg-primary"
                  btnText="Submit"
                  text={
                    <>
                      Interested candidates please send your resume to
                      <Link to="#">placement@appsysit.com</Link>
                    </>
                  }
                  fieldRequired={
                    <input
                      className="from-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Experience (in Years)"
                      required
                    />
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Placement;
