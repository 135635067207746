import React, { useEffect } from "react";
import AOS from "aos";
// import heartIcon from '../../../assets/img/icon/heart.png';

const SectionTitle = (props: any) => {
  const {
    sectionClass,
    titleClass,
    subTitleClass,
    descClass,
    title,
    subtitle,
    description,
  } = props;

  useEffect(() => {
    AOS.init();
  });
  return (
    <div
      className={sectionClass ? sectionClass : "sec-title5 mb-30 text-center"}
    >
      <div data-aos="zoom-in" data-aos-duration="1200" data-aos-delay="150">
        <h2 className={titleClass ? titleClass : "title"}>
          {title ? title : ""}
        </h2>
      </div>
      <div data-aos="zoom-in" data-aos-duration="1200" data-aos-delay="150">
        <span className={subTitleClass ? subTitleClass : "sub-title "}>
          {subtitle ? subtitle : ""}
        </span>
      </div>
      <div data-aos="zoom-in" data-aos-duration="1200" data-aos-delay="150">
        <p className={descClass ? descClass : "desc"}>
          {description ? description : ""}
        </p>
      </div>
    </div>
  );
};

export default SectionTitle;
