import React from "react";
import { Link } from "react-router-dom";

const Menuitems = (props: any) => {
  return (
    <React.Fragment>
      <li className="rs-mega-menu">
        <Link to="/home">Home</Link>
      </li>
      <li className="rs-mega-menu">
        <a href="/#about">about us</a>
      </li>
      <li className="rs-mega-menu">
        <a href="/#course">Courses</a>
      </li>
      <li className="rs-mega-menu">
        <a href="/#testimonial">testimonial</a>
      </li>
      <li className="rs-mega-menu">
        <Link to="/placement">placement</Link>
      </li>
      <li className="rs-mega-menu">
        <Link to="/contact">contact us</Link>
      </li>
    </React.Fragment>
  );
};

export default Menuitems;
