import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App/App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Assets/scss/main.scss";
import "font-awesome/css/font-awesome.css";
import { PrismicProvider } from "@prismicio/react";
import { client } from "./prismic";
import FooterStyleTwo from "./Components/Layout/Footer/FooterStyleTwo";
import Header from './Components/Layout/Header/Header';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <PrismicProvider client={client}>
      <Header />
      <App />
      <FooterStyleTwo />
    </PrismicProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
